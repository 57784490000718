<template>
  <div class="pop-ups"></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DisplayPopups extends Vue {
  display2024BlackFridayPopup = new Date() < new Date('2024-12-01');
}
</script>
